import { useCallback, useEffect, useMemo, useState, Fragment } from "react";
import { toast } from 'react-toastify';
import {v4 as uuid} from 'uuid';
import { sidebarOffset } from "../App";
import Button from "../components/Button";
import Badge from "../components/Badge";
import useData from "../utils/data";
import useGui, { useLocalStorage, useSidebar } from "../utils/gui";
import Sms from "../icons/Sms";
import Delete from "../icons/Delete";
import ArrowDropDown from "../icons/ArrowDropDown";
import DropDown from "../components/DropDown";
import SideBarHeader from "../components/SideBarHeader";
import Spinner from "../components/Spinner";
import Reset from "../icons/Reset";
import Modal from "../components/Modal";
import { useBettingVisible } from "../utils/ws";
import { useTableSorter } from "../components/TableSorter";
import { orderToBetLogs } from "../utils/tally";
import ReceiptLong from "../icons/ReceiptLong";
import { currencyScale, sidesIndian, toFixed, toIndianOddsPrice, toIndianRunsPrice, toIndianRunsSize, ts } from "../utils/utils";
import ArrowDropUp from "../icons/ArrowDropUp";
import ContentCopy from "../icons/ContentCopy";

const isDev = process.env.NODE_ENV === 'development';
isDev && toast.info('Reloaded Betting!');

const compareFixtureDisplayName = useTableSorter.getMappedCompare(bet => bet.fixtureDisplayName, useTableSorter.compareString);
const compareFixtureId = useTableSorter.getMappedCompare(bet => bet.fixtureId || '', useTableSorter.compareString);
const compareMarketDisplayName = useTableSorter.getMappedCompare(bet => bet.marketDisplayName, useTableSorter.compareString);
const compareLabel = useTableSorter.getMappedCompare(match => match.label, useTableSorter.compareString);
const compareValue = useTableSorter.getMappedCompare(match => match.value, useTableSorter.compareString);
const compareFilled = useTableSorter.getMappedCompare(match => match.filled, useTableSorter.compareString);

const cleanTeamNameRegex = /\s+\(Women\)$/;
function cleanTeamName(name)
{
  return name.replace(cleanTeamNameRegex, '');
}

function SideBar({matchFilter, oddsStyle})
{
  const [showReset, setShowReset] = useState();
  const [showLog, setShowLog] = useState();

  const orders = useData(s => s.orders);
  const betLog = useData(s => s.betLog);

  const totalBook = useMemo(() =>
  {
    const fixtureIds = matchFilter && new Set(matchFilter.map(f => f.value));
    const allBets = Array.prototype.concat.apply(betLog, (orders || []).filter(order => order.client_order_status !== 'FINALIZED').map(orderToBetLogs));
    const filteredBets = matchFilter ? allBets.filter(bet => fixtureIds.has(bet.fixtureId)) : allBets;
    const sortedBets = filteredBets.sort((l, r) => compareFixtureDisplayName(l, r) || compareFixtureId(l, r) || compareMarketDisplayName(l, r));
    const totalBook = [];
    let fixture;
    let market;
    let lastBet = {};
    for (const bet of sortedBets)
    {
      const fixtureChanged = bet.fixtureId !== lastBet.fixtureId;
      if (fixtureChanged)
      {
        fixture = {
          id: bet.fixtureId,
          displayName: bet.fixtureDisplayName,
          markets: [],
        };
        totalBook.push(fixture);
      }

      if (fixtureChanged || bet.marketDisplayName !== lastBet.marketDisplayName)
      {
        market = {
          displayName: bet.marketDisplayName,
          bets: [],
          tally: bet.side === 'Back' || bet.side === 'Lay'
            ? bet.competitors.map(competitor => ({selection: cleanTeamName(competitor), value: 0}))
            : [{selection: 'Over', value: 0}, {selection: 'Under', value: 0}],
        };
        fixture && fixture.markets.push(market);
      }

      market.bets.push(bet);
      lastBet = bet;
    }

    for (const fixture of totalBook)
    {
      for (const market of fixture.markets)
      {
        for (const bet of market.bets)
        {
          /* eslint-disable no-whitespace-before-property */
          // eslint-disable-next-line default-case
          switch (bet.side)
          {
            case "Back":
              market.tally[0].value +=
                 bet.selection === market.tally[0] .selection                                                  ?  bet.size * (bet.price - 1) :
                (bet.selection === market.tally[1] .selection || bet.selection === market.tally[2]?.selection) ? -bet.size                   : 0;
              market.tally[1].value +=
                 bet.selection === market.tally[1] .selection                                                  ?  bet.size * (bet.price - 1) :
                (bet.selection === market.tally[0] .selection || bet.selection === market.tally[2]?.selection) ? -bet.size                   : 0;
              if (market.tally[2]) market.tally[2].value +=
                 bet.selection === market.tally[2]?.selection                                                  ?  bet.size * (bet.price - 1) :
                (bet.selection === market.tally[0] .selection || bet.selection === market.tally[1] .selection) ? -bet.size                   : 0;
              break;

            case "Lay":
              market.tally[0].value +=
                 bet.selection === market.tally[0] .selection                                                  ? -bet.size * (bet.price - 1) :
                (bet.selection === market.tally[1] .selection || bet.selection === market.tally[2]?.selection) ?  bet.size                   : 0;
              market.tally[1].value +=
                 bet.selection === market.tally[1] .selection                                                  ? -bet.size * (bet.price - 1) :
                (bet.selection === market.tally[0] .selection || bet.selection === market.tally[2]?.selection) ?  bet.size                   : 0;
              if (market.tally[2]) market.tally[2].value +=
                 bet.selection === market.tally[2]?.selection                                                  ? -bet.size * (bet.price - 1) :
                (bet.selection === market.tally[0] .selection || bet.selection === market.tally[1] .selection) ?  bet.size                   : 0;
              break;

            case 'Over':
              market.tally[0].value += bet.size;
              break;

            case 'Under':
              market.tally[1].value += bet.size * (bet.price - 1);
              break;
          }
          /* eslint-ensable no-whitespace-before-property */
        }
      }
    }

    return totalBook;
  }, [orders, betLog, matchFilter]);

  const logFixture = useMemo(() => showLog && totalBook.find(fixture => fixture.id === showLog), [totalBook, showLog]);

  return <>
    <SideBarHeader>Total book</SideBarHeader>
    {matchFilter && !totalBook.length && <div className="text-center border border-yellow-300 bg-yellow-100 text-yellow-600 rounded p-4 m-4">
      <span className="inline-block rounded-full bg-yellow-600 text-yellow-100 font-bold font-mono w-7 h-7 text-xl mr-2">i</span>
      Your current filter hides all Matches
    </div>}
    <div className="overflow-y-auto">
      {totalBook.map(fixture => <div className="p-2.5 py-2" key={fixture.id}>
        <h2 className="rounded-bl border-l-2 border-b-2 border-green-600 p-1 font-bold flex gap-2">
          {fixture.displayName}
          <button type="button" className="transition-colors hover:text-green-600 ml-auto" onClick={() => setShowLog(fixture.id)}><ReceiptLong /></button>
          <button type="button" className="transition-colors hover:text-green-600" onClick={() => setShowReset({fixture})}><Reset /></button>
        </h2>
        <div className="flex pl-2">
          <div className="rounded-bl border-l-2 border-b-2 border-green-600 p-1 -mr-6 w-8" />
          <div className="py-1 text-sm w-full">
            {fixture.markets.map(market => <Fragment key={market.displayName}>
              <div className="flex">
                <h3 className="font-bold mr-auto">{market.displayName}</h3>
                <button type="button" className="transition-colors hover:text-green-600 ml-1" onClick={() => setShowReset({fixture, market})}><Reset className="w-5 h-5" /></button>
              </div>
              {market.tally.map(row => <div className="flex justify-between" key={row.selection}>
                {(oddsStyle && sidesIndian[row.selection]) || row.selection}
                <span className={row.value < 0 ? 'text-red-500' : ''}>{toFixed(row.value / currencyScale, 4)}</span>
              </div>)}
            </Fragment>)}
          </div>
        </div>
      </div>)}
    </div>
    <ModalReset {...showReset} onRequestClose={() => setShowReset()} />
    <ModalBetLog fixture={logFixture} onRequestClose={() => setShowLog()} />
  </>;
}

function ModalReset({fixture: inFixture, market: inMarket, onRequestClose, onSubmit})
{
  const ws = useGui(s => s.ws[0]);
  const [{fixture, market}, setFixture] = useState({});

  if ((inFixture && inFixture !== fixture) || inMarket !== market) setFixture({fixture: inFixture, market: inMarket});

  return <Modal className="max-w-xl bg-shade-700" isOpen={!!inFixture} onRequestClose={onRequestClose} onSubmit={() => ws.resetTally({fixtureId: fixture.id, marketDisplayName: market?.displayName}) || true}>
    <div className="font-bold border-b border-shade-800 px-5 py-1">Confirm</div>
    <div className="p-5">
      <div>
        <p>Reset tally data for {market
            ? <>
              <span className="font-bold">{fixture?.displayName}</span>/<span className="font-bold text-green-600">{market?.displayName}</span>
            </>
            : <span className="font-bold text-green-600">{fixture?.displayName}</span>}
          ?
        </p>
        <p className="text-xs">This will affect all users; only data from finalized orders is cleared.</p>
      </div>
      <div className="flex mt-4">
        <Button onClick={onRequestClose} className="ml-auto">Cancel</Button>
        <Button submit danger className="ml-2" autoFocus>Reset</Button>
      </div>
    </div>
  </Modal>;
}

function ModalBetLog({fixture: inFixture, onRequestClose})
{
  const [fixture, setFixture] = useState();
  const authToken = useGui(s => s.authToken[0]);

  if (inFixture && inFixture !== fixture) setFixture(inFixture);

  const bets = useMemo(() => fixture && Array.prototype.concat.apply([], fixture.markets.map(market => market.bets)).sort(compareFilled), [fixture]);

  return <Modal className="max-w-5xl bg-shade-700" isOpen={!!inFixture} onRequestClose={onRequestClose}>
    <div className="font-bold border-b border-shade-800 px-5 py-1">
      Bet Log for <span className="font-bold text-green-600">{fixture?.displayName}</span>
    </div>
    <div className="overflow-y-auto pt-5">
      <table className="w-full table break-all">
        <thead>
          <tr>
            <th className="w-5" />
            <th>Order ID</th>
            <th>Filled</th>
            <th>User</th>
            <th>Display Name</th>
            <th>Side</th>
            <th>Selection</th>
            <th className="text-right">Price</th>
            <th className="text-right">Size</th>
            <th className="w-5" />
          </tr>
        </thead>
        <tbody>
          {bets?.map(bet =>
          {
            const isRuns = sidesIndian[bet.side];
            const isUnder = bet.side === 'Under';
            return <tr key={bet.fillId}>
              <td />
              <td className="text-xs">{bet.orderId}</td>
              <td className="tabular-num">{ts(bet.filled)}</td>
              <td>{bet.userId ? (authToken.per?.has('admin') ? `#${bet.userId} ${bet.userName}` : bet.userName) : ''}</td>
              <td>{bet.marketDisplayName}</td>
              <td>{sidesIndian[bet.side] || bet.side}</td>
              <td>{isRuns ? Math.ceil(bet.selection) : bet.selection}</td>
              {/* <td className="text-right tabular-num">{toFixed(bet.price, 2)}</td> */}
              <td className="text-right tabular-num">{toFixed(isRuns ? toIndianRunsPrice(isUnder, bet.price) : toIndianOddsPrice(bet.price), 2)}</td>
              <td className="text-right tabular-num">{toFixed((isRuns ? toIndianRunsSize(isUnder, bet.price, bet.size) : bet.size) / currencyScale, 2)}</td>
              <td />
            </tr>;
          })}
        </tbody>
      </table>
    </div>
    <div className="flex mt-4 m-5">
      <Button onClick={onRequestClose} className="ml-auto">OK</Button>
    </div>
  </Modal>;
}

const deleteOrder = (order) => useData.set(s =>
{
  const index = s.orders.findIndex(o => o.order_id === order.order_id);
  if (index >= 0) s.orders.splice(index, 1);
});

const gridCols = [
  'grid-cols-none',
  'grid-cols-1',
  'grid-cols-2',
  'grid-cols-3',
  'grid-cols-4',
];
export default function Betting()
{
  useBettingVisible();
  const orders = useData(s => s.orders);

  const [bettingLayout, setBettingLayout] = useLocalStorage('bettingLayout', 3);
  const [matchFilter, setMatchFilter] = useLocalStorage('matchFilter', null);
  const [marketTypeFilter, setMarketTypeFilter] = useLocalStorage('marketTypeFilter', null);
  const [oddsStyle, setOddsStyle] = useLocalStorage('oddsStyle', 1);
  const betLog = useData(s => s.betLog);

  const availableMatches = [
    ...(orders || []).map(order => ({label: order.fixture.display_name, value: order.fixture.additional_info.FixtureId})),
    ...(matchFilter || []),
    ...(betLog || []).map(bet => ({label: bet.fixtureDisplayName, value: bet.fixtureId})),
  ]
    .filter((f, i, a) => a.findIndex(f2 => f2.value === f.value) === i)
    .sort((l, r) => compareLabel(l, r) || compareValue(l, r));
  const availableMarketTypes = [...new Set([
    ...(orders || []).map(order => order.market.market_type),
    ...(marketTypeFilter || []),
  ])].sort();

  useSidebar(SideBar, useMemo(() => ({matchFilter, oddsStyle}), [matchFilter, oddsStyle]));

  const finishedOrders = (orders || []).filter(o => o.client_order_status === 'FINALIZED');

  const filteredOrders = orders?.filter(order => (!matchFilter || matchFilter.find(filter => filter.value === order.fixture.additional_info.FixtureId)) && (!marketTypeFilter || marketTypeFilter.includes(order.market.market_type)));

  return <>
    <div className="-m-4 mb-0 h-16 relative z-20">
      <div className={`fixed right-0 top-16 ${sidebarOffset} h-4 overflow-hidden pointer-events-none`}>
        <div className="shadow-md w-full h-full -mt-4" />
      </div>
      <div className={`fixed right-0 top-0 ${sidebarOffset} h-16 bg-gray-50 px-2 py-2 flex`}>

        <div className="flex divide-x divide-gray-200">
          <div className="flex flex-col items-center justify-between px-2">
            <DropDown button={<Button primary={!!matchFilter}>{matchFilter ? `${matchFilter.length} ${matchFilter.length === 1 ? 'Match' : 'Matches'}` : 'Show all'}<ArrowDropDown className="inline -my-4 -mr-2" /></Button>}>
              <div className="bg-white border border-gray-200 rounded overflow-hidden shadow-lg">
                <div className="max-h-96 overflow-y-auto">
                  {availableMatches.map(match => <label className="hover:bg-gray-50 block whitespace-nowrap px-2 first:pt-1" key={match.value}>
                    <input className="mr-1"
                      type="checkbox"
                      checked={!!matchFilter?.find(filter => filter.value === match.value)}
                      onChange={e => setMatchFilter(e.target.checked ? (matchFilter ? matchFilter.concat(match) : [match]) : matchFilter.filter(filter => filter.value !== match.value))} />
                    {match.label}
                  </label>)}
                </div>
                <div className="text-center p-2"><Button sm onClick={e => { e.target.blur(); setMatchFilter(null); }}>Show all</Button></div>
              </div>
            </DropDown>
            <span className="text-xs">Matches</span>
          </div>

          <div className="flex flex-col items-center justify-between px-2">
            <DropDown button={<Button primary={!!marketTypeFilter}>{marketTypeFilter ? `${marketTypeFilter.length} ${marketTypeFilter.length === 1 ? 'Type' : 'Types'}` : 'Show all'}<ArrowDropDown className="inline -my-4 -mr-2" /></Button>}>
              <div className="bg-white border border-gray-200 rounded overflow-hidden shadow-lg">
                <div className="max-h-96 overflow-y-auto">
                  {availableMarketTypes.map(marketType => <label className={`hover:bg-gray-50 block whitespace-nowrap px-2 first:pt-1`} key={marketType}>
                    <input className="mr-1"
                      type="checkbox"
                      checked={!!marketTypeFilter?.includes(marketType)}
                      onChange={e => setMarketTypeFilter(e.target.checked ? (marketTypeFilter ? marketTypeFilter.concat(marketType) : [marketType]) : marketTypeFilter.filter(id => id !== marketType))} />
                    {marketType}
                  </label>)}
                </div>
                <div className="text-center p-2"><Button sm onClick={e => { e.target.blur(); setMarketTypeFilter(null); }}>Show all</Button></div>
              </div>
            </DropDown>
            <span className="text-xs">Market Types</span>
          </div>
        </div>

        <div className="mx-auto">
          <div className="flex flex-col items-center justify-between px-2">
            <Button primary={!!finishedOrders.length} onClick={() => finishedOrders.forEach(deleteOrder)}>{finishedOrders.length} finished {finishedOrders.length === 1 ? 'Order' : 'Orders'}</Button>
            <span className="text-xs">Clean up</span>
          </div>
        </div>

        <div className="flex divide-x divide-gray-200">
          <div className="flex flex-col items-center justify-between text-xs px-2">
            <div className="flex items-center">
              <button type="button" className={`btn-tool ${oddsStyle === 0 ? 'btn-primary' : 'btn-secondary'}`} onClick={() => setOddsStyle(0)}>Betfair</button>
              <button type="button" className={`btn-tool ${oddsStyle === 1 ? 'btn-primary' : 'btn-secondary'}`} onClick={() => setOddsStyle(1)}>Indian</button>
            </div>
            Odds Style
          </div>

          <div className="flex flex-col items-center justify-between text-xs px-2">
            <div className="flex items-center">
              <button type="button" className={`btn-layout ${bettingLayout === 1 ? 'btn-primary' : 'btn-secondary'} ${gridCols[1]}`} onClick={() => setBettingLayout(1)}><div /><div /><div /></button>
              <button type="button" className={`btn-layout ${bettingLayout === 2 ? 'btn-primary' : 'btn-secondary'} ${gridCols[2]}`} onClick={() => setBettingLayout(2)}><div /><div /><div /><div /><div /></button>
              <button type="button" className={`btn-layout ${bettingLayout === 3 ? 'btn-primary' : 'btn-secondary'} ${gridCols[3]}`} onClick={() => setBettingLayout(3)}><div /><div /><div /><div /><div /><div /><div /><div /></button>
              <button type="button" className={`btn-layout ${bettingLayout === 4 ? 'btn-primary' : 'btn-secondary'} ${gridCols[4]}`} onClick={() => setBettingLayout(4)}><div /><div /><div /><div /><div /><div /><div /><div /><div /><div /><div /></button>
            </div>
            Layout
          </div>
        </div>

      </div>
    </div>
    <div className={`grid gap-4 mt-4 ${gridCols[bettingLayout]}`}>
      {filteredOrders?.map(order => <Order order={order} key={order.order_id} {...{oddsStyle}} />)}
    </div>
    {filteredOrders && (matchFilter || marketTypeFilter) && !filteredOrders.length && <div className="text-center border border-yellow-300 bg-yellow-100 text-yellow-600 rounded p-4">
      <span className="inline-block rounded-full bg-yellow-600 text-yellow-100 font-bold font-mono w-7 h-7 text-xl mr-2">i</span>
      Your current filter hides all Orders
    </div>}
  </>;
}

function toastError(e)
{
  if (!e?.details)
  {
    e.details('Bad error, see console');
    console.log('Bad error:', e);
  }

  toast.error(e.details);
}

function orderFlags(order)
{
  return {
    isLive: order.client_order_status === 'LIVE',
    isStopped: order.client_order_status === 'STOPPED',
    isFinalized: order.client_order_status === 'FINALIZED',
    isUnacknowledged: order.agent_order_status === 'ORDER_UNACKNOWLEDGED',
    isSystemAcknowledged: order.agent_order_status === 'ORDER_SYSTEM_ACKNOWLEDGED',
    isTraderAcknowledged: order.agent_order_status === 'TRADER_ACKNOWLEDGED',
  };
}

const textColor = {
  STOPPED: 'text-gray-500',
  FINALIZED: 'text-gray-300',
}
function Order({order, oddsStyle})
{
  const ws = useGui(s => s.ws[0]);

  const o = orderFlags(order);
  const orderReq = {order_id: order.order_id, order_version: order.version};

  const interactionRequested = order.human_interaction_required_by_agent || order.human_interaction_required_by_client;
  const showPriceChanged = order.priceChanged && o.isLive;
  const highlightOrder = interactionRequested || showPriceChanged;
  const borderColor = highlightOrder ? 'border-yellow-400' : o.isStopped ? 'border-red-400' : 'border-gray-300';

  function toggleInteraction()
  {
    ws[order.human_interaction_required_by_agent ? 'unflagHumanInteractionRequired' : 'flagHumanInteractionRequired'](orderReq).catch(toastError);
  }

  useEffect(() =>
    {
      if (o.isTraderAcknowledged) return null;
      const timeout = setTimeout(() => ws.traderAcknowledgeOrder(orderReq).catch(Boolean), 100);
      return () => clearTimeout(timeout);
    },
    // eslint-disable-next-line
    [order.version]);

  const marketType = order.market.market_type.toLowerCase();
  const isRuns = marketType === 'inningsruns' || marketType === 'sessionruns';

  async function copyOrder()
  {
    try
    {
      await navigator.clipboard.writeText(`${order.fixture.competition}, ${order.fixture.display_name}, https://www.betfair.com/exchange/plus/tennis/market/${order.market.additional_info.BetfairMarketId}`);
      toast.success('Order copied to clipboard!');
    }
    catch
    {
      toast.success('Could not copy to clipboard');
    }
  }

  return <div className={`border ${textColor[order.client_order_status]} ${borderColor} ${highlightOrder ? 'shadow-yellow-lg' : o.isStopped && 'shadow-red-lg'} border-opacity-50 rounded overflow-hidden`}>
    <div className={`rounded-t h-9 px-2 border-b ${borderColor} ${highlightOrder ? 'bg-yellow-50' : o.isStopped ? 'bg-red-50' : 'bg-gray-50'} border-opacity-50 flex gap-2 items-center whitespace-nowrap`}>
      {isDev && <button type="button" className="p-0 h-full btn-secondary btn-link focus:outline-none" onClick={() => toast.info(<pre className="text-2xs leading-none">{JSON.stringify(order, null, 2)}</pre>, {autoClose: false})}>🪲</button>}
      <div className="min-w-0 flex flex-col">
        <span className={`overflow-hidden overflow-ellipsis ${isRuns && 'text-xs mt-0.5 -mb-2'}`}>{order.fixture.display_name}</span>
        {isRuns && <span className="overflow-hidden overflow-ellipsis">{order.market.display_name}</span>}
      </div>
      {showPriceChanged && <Badge warning className="min-w-0">Price changed</Badge>}
      {!o.isLive && <Badge className={`min-w-0 ${o.isStopped && 'text-red-400'}`}>{order.client_order_status}</Badge>}
      {o.isUnacknowledged && <Badge danger className="min-w-0">{order.agent_order_status}</Badge>}
      {!o.isTraderAcknowledged && o.isLive && <Button primary sm onClick={() => ws.traderAcknowledgeOrder(orderReq).catch(toastError)}>Ack</Button>}
      {order.human_interaction_required_by_client && <Badge warning className="min-w-0">Interaction requested</Badge>}
      <button type="button" className="p-1" onClick={copyOrder}><ContentCopy className="w-4 h-4" /></button>
      <span className="text-xs ml-auto">{ts(order.created_time)}</span>
      <div className="flex overflow-hidden rounded-tr -mr-2 flex-shrink-0 h-full">
        {!o.isFinalized && <button type="button" className={`p-1 h-full btn-warning focus:outline-none flex-shrink-0 ${!order.human_interaction_required_by_agent && 'btn-link'}`} onClick={toggleInteraction}><Sms /></button>}
        {o.isFinalized && <button type="button" className="p-1 h-full btn-secondary btn-link focus:outline-none flex-shrink-0" onClick={() => deleteOrder(order)}><Delete /></button>}
      </div>
    </div>
    <div className="flex flex-col gap-3 p-2">
      {order.sub_orders.map(subOrder => <SubOrder order={order} subOrder={subOrder} fills={order.fills} key={subOrder.key_json} {...{oddsStyle, isRuns, showPriceChanged}} />)}
    </div>
  </div>;
}

function subOrderFlags(subOrder)
{
  return {
    isUnacknowledged: subOrder.agent_sub_order_status === 'SUB_ORDER_UNACKNOWLEDGED',
    isAcknowledged: subOrder.agent_sub_order_status === 'SUB_ORDER_ACKNOWLEDGED',
    isUpdated: subOrder.agent_sub_order_status === 'CLIENT_UPDATED',
    isWorking: subOrder.agent_sub_order_status === 'WORKING',
    isRejected: subOrder.agent_sub_order_status === 'REJECTED',
    isFinished: subOrder.agent_sub_order_status === 'FINISHED',
  };
}

const rejectReasons = [
  'Ball Running',
  'Price gone',
];

const fromIndianRunsPrice = (isUnder, price) => isUnder ? (h => h / (h - 1))((price + 100) / 100) : (price + 100) / 100;
const fromIndianOddsPrice = price => price / 100 + 1;
const fromIndianRunsSize = (isUnder, betFairPrice, size) => isUnder ? size / (betFairPrice - 1) : size;
const printSubOrderPrice = (isUnder, isRuns, isIndian, price) => price === 0
  ? 'Market'
  : isIndian
    ? toFixed(isRuns
      ? toIndianRunsPrice(isUnder, price)
      : toIndianOddsPrice(price), 2)
    : toFixed(price, 4);

const editAllSymbol = Symbol('edit all');
function SubOrder({order, subOrder, fills, oddsStyle, isRuns, showPriceChanged})
{
  const ws = useGui(s => s.ws[0]);

  const filteredFills = fills.filter(f => f.sub_order_key_json === subOrder.key_json);
  const fillsById = useMemo(() => Object.fromEntries(filteredFills.map(f => [f.fill_id, f])), [filteredFills]);

  const [editingFills, setEditingFills] = useState({});
  const [preFills, setPreFills] = useState({});
  const [fillIds, setFillIds] = useState(filteredFills.map(f => f.fill_id));
  const [editingAll, setEditingAll] = useState();
  const [lastFillPrice, setLastFillPrice] = useState('');

  const o = orderFlags(order);
  const s = subOrderFlags(subOrder);

  const orderReq = {order_id: order.order_id, order_version: order.version};
  const subOrderReq = {...orderReq, sub_order_keys: [subOrder.key]};

  function cancelFill(fill)
  {
    if (!fill) return;
    useData.set(s =>
    {
      const foundfill = s.orders.find(o => o.order_id === order.order_id)
        ?.fills.find(f => f.fill_id === fill.fill_id);
      if (foundfill) foundfill.agentCancelRequested = true;
    });
    ws.cancelFill({...orderReq, fill_id: fill.fill_id}).catch(toastError);
  }
  const saveFill = (fill, forceUpdate) =>
  {
    if (!fill) return;
    let agent_fill_metadata;
    if ((fill.side !== undefined && fill.side !== subOrder.key.values.Side) || (fill.selection !== undefined && fill.selection !== subOrder.key.values.Selection))
    {
      agent_fill_metadata = {};
      if (fill.side !== undefined) agent_fill_metadata.FillSide = fill.side;
      if (fill.selection !== undefined) agent_fill_metadata.FillSelection = fill.selection;
    }

    delete fill.side;
    delete fill.selection;
    fill.size *= currencyScale;
    if (editingFills[fill.fill_id] || forceUpdate) ws.updateFill({...orderReq, ...fill, agent_fill_metadata}).then(deletePreFill(fill.fill_id)).catch(toastError);
    else ws.fillSubOrder({...orderReq, ...fill, sub_order_key: subOrder.key, agent_fill_metadata}).catch(toastError);
  };
  function editFill(fill)
  {
    setEditingFills({...editingFills, [fill.fill_id]: true});
    setPreFills({...preFills, [fill.fill_id]: {fill_id: fill.fill_id, price: fill.price, size: fill.size, selection: fill.agent_metadata?.FillSelection || fill.sub_order_key.values.Selection}});
  }

  const addPreFill = useCallback(() =>
  {
    const fill_id = uuid();
    setPreFills({...preFills, [fill_id]: {fill_id, price: subOrder.price.value || lastFillPrice, size: 0, side: subOrder.key.values.Side, selection: subOrder.key.values.Selection}});
    setFillIds(fillIds.concat(fill_id));
  }, [setPreFills, setFillIds, preFills, fillIds, subOrder.price.value, subOrder.key.values.Side, subOrder.key.values.Selection]);
  const deletePreFill = id =>
  {
    const {[id]: _, ...newPreFills} = preFills;
    const {[id]: __, ...newEditingFills} = editingFills;
    setFillIds(fillIds.filter(f => f.fill_id !== id));
    setPreFills(newPreFills);
    setEditingFills(newEditingFills);
  };

  const newFills = filteredFills.filter(fill => !fillIds.includes(fill.fill_id));
  if (newFills.length) setFillIds(Array.prototype.concat.apply(fillIds, newFills.map(fill => fill.fill_id)));

  const borderColor = o.isLive
    ? (s.isFinished
      ? 'border-gray-600'
      : s.isRejected
        ? 'border-red-600'
        : 'border-green-600')
    : o.isFinalized
      ? 'border-gray-200'
      : 'border-gray-300';

  const isIndian = oddsStyle === 1;
  const isUnder = subOrder.key.values.Side.toLowerCase() === 'under';
  const fillProps = {isIndian, isRuns, isUnder};

  const numPreFills = Object.keys(preFills).filter(id => !editingFills[id]).length;
  if (s.isWorking && numPreFills === 0) addPreFill();

  const isBetMax = subOrder.additional_info.BetMax === 'True';

  return <div>
    <div className={`flex gap-2 items-center border-b-2 border-l-2 rounded-bl ${borderColor} pl-2 pb-1`}>
      {isIndian && isRuns
        ? <span className="font-bold">{Math.ceil(subOrder.key.values.Selection)} {isUnder ? 'No' : 'Yes'}</span>
        : <span>{subOrder.display_name}</span>}
      <span className="tabular-nums">
        <span className="text-xs">Price</span> <div className="inline-block relative">
          {showPriceChanged && <span className="text-xs opacity-50 absolute bottom-full leading-none -mb-1.5">{printSubOrderPrice(isUnder, isRuns, isIndian, subOrder.oldPrice)}</span>}
          {printSubOrderPrice(isUnder, isRuns, isIndian, subOrder.price.value)}
        </div>
      </span>
      <span className="tabular-nums"><span className="text-xs">Size</span> <span className={!isBetMax ? `${o.isLive ? 'bg-green-300' : ''} p-1.5 py-1 rounded` : ''}>{
        isBetMax
          ? 'Max'
          : toFixed((isIndian && isRuns
            ? toIndianRunsSize(isUnder, subOrder.price.value, subOrder.size.value)
            : subOrder.size.value) / currencyScale, 2, false)
        }</span></span>
      <div className="ml-auto flex gap-2">
        {s.isRejected && <Badge danger={o.isLive} className="ml-auto">{subOrder.reject_reason}</Badge>}
        {s.isAcknowledged && o.isLive && <Button sm primary onClick={() => ws.workSubOrder(subOrderReq).catch(toastError)}>Accept</Button>}
        {s.isAcknowledged && o.isLive && !fills.length && <DropDown button={<Button sm danger>Reject<ArrowDropDown className="inline -my-4 -mr-2" /></Button>}>
          <div className="bg-white shadow-lg border border-red-200 rounded text-red-500 text-sm overflow-hidden">
            {rejectReasons.map(reason => <button type="button" key={reason} className="block w-full text-left font-bold hover:bg-red-50 px-3 py-1 transition-colors" onClick={() => ws.rejectSubOrder({...subOrderReq, reason}).catch(toastError)}>{reason}</button>)}
          </div>
        </DropDown>}
        {s.isWorking && <Button sm onClick={addPreFill}>Add Fill</Button>}
        {(s.isWorking || s.isAcknowledged) && <Button sm onClick={() => ws.finishSubOrder(subOrderReq).catch(toastError)}>Finish</Button>}
      </div>
    </div>
    { !!fillIds.length && <div className="flex">
      <div className={`border-l-2 border-b-2 rounded-bl ${borderColor} w-8 -mr-6 ml-2 flex-shrink-0`} />
        <table className="table w-full mb-2">
        <thead>
          <tr className="text-xs">
            <th>Status</th>
            {isRuns && <>
              <th>Selection</th>
            </>}
            <th>Price</th>
            <th>Size</th>
            <th>Filled</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {fillIds.map(id =>
          {
            const fill = fillsById[id];
            const preFill = preFills[id];
            const editing = editingFills[id];

            if (fill && !editing)
            {
              if (preFill)
              {
                const {[id]: _, ...otherPrefills} = preFills;
                setPreFills(otherPrefills);
              }

              return <Fill fill={fill} key={id} onCancel={s.isWorking && (() => cancelFill(fill))} onEdit={s.isWorking && (() => editFill(fill))} {...fillProps} />;
            }

            return preFill && <FillForm
              fill={preFill}
              key={id}
              onSubmit={s.isWorking && (e =>
                {
                  e.preventDefault();
                  if (preFill.price === '') return e.target.bettingSystemPrice.focus();
                  if (!preFill.size) return e.target.bettingSystemSize.focus();
                  saveFill(preFill);
                  setLastFillPrice(preFill.price);
                })}
              deletePreFill={deletePreFill}
              updateFill={fn => { const newPreFill = {...preFill}; fn(newPreFill); setPreFills({...preFills, [id]: newPreFill}); }}
              allowCancel={numPreFills !== 1}
              subOrderKey={subOrder.key}
              editing={editing}
              {...fillProps} />;
          })}
          {editingAll
            ? <FillForm
              className="border-t-2 border-gray-400"
              fill={editingAll}
              onSubmit={s.isWorking && (e =>
                {
                  e.preventDefault();
                  for (const fill of filteredFills)
                  {
                    if (fill.fill_status !== 'CLIENT_ACCEPTED') continue;
                    saveFill({
                      ...fill,
                      ...editingAll,
                      size: isIndian && isRuns && isUnder ? fromIndianRunsSize(isUnder, editingAll.price, toIndianRunsSize(isUnder, fill.price, fill.size)) : fill.size,
                    }, true);
                  }

                  setLastFillPrice(editingAll.price);
                  setEditingAll();
                })}
              deletePreFill={() => setEditingAll()}
              updateFill={fn => { const newPreFill = {...editingAll}; fn(newPreFill); setEditingAll(newPreFill); }}
              allowCancel
              subOrderKey={subOrder.key}
              editing={editAllSymbol}
              {...fillProps} />
            : s.isWorking && <tr className="border-t-2 border-gray-400">
              <td colSpan="100" className="text-right">
                <Button sm className="whitespace-nowrap" onClick={() =>
                {
                  const editableFills = filteredFills.filter(fill => fill.fill_status === 'CLIENT_ACCEPTED');
                  if (!editableFills.length) return toast.info('No editable fills');

                  setEditingAll({
                    selection: isRuns && (Math.round(editableFills.reduce((sum, fill) => sum + parseFloat('FillSelection' in fill.agent_metadata ? fill.agent_metadata.FillSelection : fill.sub_order_key.values.Selection), 0) / editableFills.length + 0.5) - 0.5),
                    price: editableFills.reduce((sum, fill) => sum + fill.price, 0) / editableFills.length,
                  });
                }}>Edit All</Button>
              </td>
            </tr>}
        </tbody>
      </table>
    </div>}
  </div>;
}

const invalidToZero = value => isNaN(value) || !isFinite(value) ? 0 : value;
const toFloat = str => invalidToZero(parseFloat(str.replace(',', '.')));
const toInt = str => invalidToZero(parseInt(str.replace(',', '.')));

function Fill({fill, onCancel, onEdit, isIndian, isRuns, isUnder})
{
  const fillSelection = 'FillSelection' in fill.agent_metadata ? fill.agent_metadata.FillSelection : fill.sub_order_key.values.Selection;
  return <tr>
    <td className="text-xs leading-none">{fill.fill_status.toLowerCase().replace('_', ' ')}</td>
    {isRuns && <>
      <td className={fillSelection === fill.sub_order_key.values.Selection ? 'opacity-50' : ''}>{isIndian ? Math.ceil(fillSelection) : fillSelection}</td>
    </>}
    <td className="tabular-nums">{isIndian ? toFixed(isRuns ? toIndianRunsPrice(isUnder, fill.price) : toIndianOddsPrice(fill.price), 2) : toFixed(fill.price, 4)}</td>
    <td className="tabular-nums">{isIndian ? toFixed((isRuns ? toIndianRunsSize(isUnder, fill.price, fill.size) : fill.size) / currencyScale, 2) : toFixed(fill.size / currencyScale, 4)}</td>
    <td className="text-xs leading-none">{fill.filled_time && ts(fill.filled_time)}</td>
    <td className="w-0">
      <div className="flex h-full w-full gap-2 justify-end">
        {onEdit && fill.fill_status === 'CLIENT_ACCEPTED' && <Button sm onClick={() => onEdit(fill.fill_id)}>Edit</Button>}
        {onCancel && fill.fill_status !== 'AGENT_CANCELLED' && fill.fill_status !== 'CLIENT_REJECTED' && <Button sm danger disabled={fill.agentCancelRequested} className="w-16" onClick={() => onCancel(fill.fill_id)}>{fill.agentCancelRequested ? <div className="-my-2"><Spinner /></div> : 'Cancel'}</Button>}
      </div>
    </td>
  </tr>;
}

const toIndianFill = (isUnder, fill, isRuns) => ({
  side: sidesIndian[fill.side],
  selection: Math.ceil(fill.selection),
  price: fill.price === '' ? fill.price : toFixed(isRuns ? toIndianRunsPrice(isUnder, fill.price) : toIndianOddsPrice(fill.price), 2),
  size: toIndianRunsSize(isUnder, fill.price, fill.size),
});
function FillForm({fill: fillBetfair, updateFill, onSubmit, deletePreFill, isIndian, isRuns, isUnder, allowCancel, subOrderKey, editing, className})
{
  const formId = `form_fill_${fillBetfair.fill_id}`;

  const [fillIndian, setFillIndian] = useState(toIndianFill(isUnder, fillBetfair, isRuns));
  const fill = isIndian ? fillIndian : fillBetfair;
  const editingAll = editing === editAllSymbol;

  useEffect(
    () => { isIndian && setFillIndian(toIndianFill(isUnder, fillBetfair, isRuns)); },
    // eslint-disable-next-line
    [isIndian, isRuns]);

  return <tr className={className}>
    <td className="italic">{editing ? (editingAll ? 'edit all' : 'editing') : 'draft'}</td>
    {onSubmit
      ? <>
        {isRuns && <td className="no-p">
          <input type="number" className="w-full focus:z-10 relative" step={isIndian ? 1 : 'any'} form={formId}
            value={fill.selection || ''}
            onChange={e => updateFill(f =>
            {
              const selection = (isIndian ? toInt : toFloat)(e.target.value);
              setFillIndian({...fillIndian, selection}); f.selection = isIndian ? selection - 0.5 : selection;
              })} />
        </td>}
        <td className="no-p">
          <SpinInput form={formId} name="bettingSystemPrice" autoFocus={fill.price === ''} tick={isIndian ? 1 : 0.01}
            value={fill.price}
            onChange={e => updateFill(f =>
            {
              const price = toFloat(e.target.value);
              f.price = isIndian ? (isRuns ? fromIndianRunsPrice(isUnder, price) : fromIndianOddsPrice(price)) : price;
              if (isIndian && isRuns && isUnder) f.size = fromIndianRunsSize(isUnder, f.price, fillIndian.size);
              setFillIndian({...fillIndian, price});
            })} />
        </td>
        <td className="no-p">
          {!editingAll && <input type="number" className="w-full focus:z-10 relative" step="any" form={formId} name="bettingSystemSize" autoFocus={fill.price !== ''}
            value={fill.size || ''}
            onChange={e => updateFill(f =>
            {
              const size = toFloat(e.target.value);
              setFillIndian({...fillIndian, size});
              f.size = isIndian && isRuns ? fromIndianRunsSize(isUnder, f.price, size) : size;
            })} />}
        </td>
      </>
      : <>
        {isRuns && <>
          <td className={fillBetfair.selection === subOrderKey.values.Selection ? 'opacity-50' : ''}>{fill.selection}</td>
        </>}
        <td className="tabular-nums">{toFixed(fill.price, isIndian ? 2 : 4)}</td>
        <td className="tabular-nums">{!editingAll && toFixed(fill.size, isIndian ? 2 : 4)}</td>
      </>}
    <td className="text-center">-</td>
    <td className="w-0">
      {onSubmit && <form className="flex h-full w-full gap-2 justify-end" id={formId} onSubmit={onSubmit}>
        <Button sm primary submit>Send</Button>
        <Button sm danger onClick={() => deletePreFill(fillBetfair.fill_id)} disabled={!allowCancel && !editing} className="w-16">Cancel</Button>
      </form>}
    </td>
  </tr>;
}

function SpinInput({tick, value, onChange, ...props})
{
  function change(offset)
  {
    onChange({target: {value: toFixed((typeof value === 'number' ? value : toFloat(value)) + offset, 5)}});
  }

  function keyDown(e)
  {
    switch (e.code)
    {
      case 'ArrowUp': value !== '' && change(tick); break;
      case 'ArrowDown': value !== '' && change(-tick); break;
      default: return;
    }

    e.preventDefault();
  }

  return <label className="w-full block relative focus-within:z-10">
    <input type="number" className="w-full pr-6" step="any" {...{value, onChange, ...props}} onKeyDown={keyDown} />
    {value !== '' && <div className="flex flex-col absolute top-0 right-0 h-full w-auto border-l border-gray-200 divide-y divide-gray-200">
      <button type="button" className="transition hover:bg-gray-200 active:bg-gray-300 rounded-tr h-1/2" onClick={() => change(tick)}><ArrowDropUp className="w-4 h-4" /></button>
      <button type="button" className="transition hover:bg-gray-200 active:bg-gray-300 rounded-br h-1/2" onClick={() => change(-tick)}><ArrowDropDown className="w-4 h-4" /></button>
    </div>}
  </label>;
}
