import { NavLink } from "react-router-dom";
import ArrowBack from "../icons/ArrowBack";

export default function SideBarHeader({children, back = false})
{
  return <div className="bg-green-500 text-white font-bold py-2 px-4 shadow flex z-10">
    {back && <NavLink to=".."><ArrowBack className="w-6 h-6" /></NavLink>}
    {children}
  </div>;
}
