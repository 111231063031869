import useGui from "../utils/gui";

export default function Alert()
{
  const [showPopup, setShowPopup] = useGui(s => s.showPopup);

  return <>
    <div className="w-full flex flex-col items-center justify-center relative bg-yellow-200">
      <div className="absolute inset-0 bg-gradient-to-br from-green-600 via-yellow-200 to-pink-600 animate-pulse" />
      <h1 className="text-2xl font-bold animate-bounce">A new Order has arrived!</h1>
    </div>
    <div className="absolute w-full bottom-1 text-center">
      <label className="text-xs"><input className="scale-75 -mb-1" type="checkbox" checked={!showPopup} onChange={e => setShowPopup(!e.target.checked)} /> Don't show this any more</label>
    </div>
  </>;
}
