import { useEffect } from "react";
import ReconnectingWebSocket from "reconnecting-websocket";
import useData, { resToJson } from "./data";
import useGui from "./gui";
import { stringify, parse } from "./json";
import {v4 as uuid} from 'uuid';
import { toast } from "react-toastify";
import { orderToBetLogs } from "../utils/tally";

let alertAudioElementNewOrder = null;
let alertAudioElementPriceChanged = null;
let alertAudioElementOrderStopped = null;
let pingTimeout;
const messageHandlers = {
  loginFailed(reason)
  {
    useGui.set(s => { s.wsStatus = {color: 'red', message: reason}; });
  },
  orders(data)
  {
    const per = useGui.getState().authToken[0].per;
    const filteredOrders = data.filter(order => per.has(order.fixture.sport));
    useGui.set(s => { s.wsStatus = {color: 'green', message: 'connected'}; });
    filteredOrders.forEach(fixUpOrder);
    useData.set(s => { s.orders = filteredOrders; });
  },
  orderUpdated(order)
  {
    if (!useGui.getState().authToken[0].per.has(order.fixture.sport)) return;
    useData.set(s =>
    {
      fixUpOrder(order);
      const index = s.orders.findIndex(o => o.order_id === order.order_id);
      if (index >= 0)
      {
        const oldOrder = s.orders[index];
        s.orders[index] = order;

        order.priceChanged = oldOrder.priceChanged;
        order.priceChangedTimeout = oldOrder.priceChangedTimeout;

        let priceChanged;
        for (const subOrder of order.sub_orders)
        {
          const oldSubOrder = oldOrder.sub_orders.find(s => s.key_json === subOrder.key_json);
          subOrder.oldPrice = oldSubOrder.oldPrice;

          // fudge state to keep UI from twitching
          const newStatus = subOrder.agent_sub_order_status;
          if (oldSubOrder.agent_sub_order_status === 'WORKING' && (newStatus === 'CLIENT_UPDATED' || newStatus === 'SUB_ORDER_ACKNOWLEDGED')) subOrder.agent_sub_order_status = 'WORKING';

          if (oldSubOrder && oldSubOrder.price.value !== subOrder.price.value)
          {
            subOrder.oldPrice = oldSubOrder.price.value;
            order.priceChanged = priceChanged = true;
          }
        }

        if (priceChanged)
        {
          const id = order.order_id;
          const timeout = order.priceChangedTimeout = window.setTimeout(() => useData.set(s =>
          {
            const order = s.orders.find(o => o.order_id === id);
            if (order && order.priceChangedTimeout === timeout) order.priceChanged = false;
          }), 10 * 1000);
          alertAudioElementPriceChanged && useGui.getState().playAudioPriceChanged[0] && alertAudioElementPriceChanged.play();
        }

        oldOrder.client_order_status !== order.client_order_status && order.client_order_status === 'STOPPED' && alertAudioElementOrderStopped && useGui.getState().playAudioOrderStopped[0] && alertAudioElementOrderStopped.play();
      }
      else
      {
        s.orders.push(order);
        alertAudioElementNewOrder && useGui.getState().playAudioNewOrder[0] && alertAudioElementNewOrder.play();
        (document.hidden || !bettingVisible) && useGui.getState().showPopup[0] && showAlert();
      }

      if (order.client_order_status === 'FINALIZED')
      {
        const newBetLogs = orderToBetLogs(order);
        if (newBetLogs.length) s.betLog = s.betLog.concat(newBetLogs);
      }
    });
  },
  betLog(data)
  {
    const per = useGui.getState().authToken[0].per;
    useData.set(s => { s.betLog = data.filter(log => per.has(log.sport)); });
  },
  resetTally({fixtureId, marketDisplayName})
  {
    const pred = marketDisplayName != null
      ? bet => bet.fixtureId !== fixtureId || bet.marketDisplayName !== marketDisplayName
      : bet => bet.fixtureId !== fixtureId;
    useData.set(s => { s.betLog = s.betLog.filter(pred); });
  },
  apiConnected(isConnected)
  {
    useGui.set(s => { s.apiConnected = isConnected; });
  },
};

let loginSocket = null;
const wsEvents = {
  async open()
  {
    console.log('socket open');
    useGui.set(s => { s.wsStatus = {color: 'yellow', message: 'logging in'}; });
    loginSocket(this);
  },
  close(e)
  {
    console.log('socket closed', e);
    useGui.set(s => { s.wsStatus = {color: 'red', message: 'disconnected'}; });
  },
  message(e)
  {
    if (e.data === '') return clearTimeout(pingTimeout);

    let message;
    try
    {
      message = parse(e.data);
    }
    catch (e)
    {
      return console.log('received invalid message', e.data);
    }

    const handler = messageHandlers[message.message];
    if (handler)
    {
      try
      {
        handler.call(this, message.data);
      }
      catch (e)
      {
        console.log('unhandled exception handling message', message.message, e);
      }
    }
    else console.log('Received unknown message', message.message);
  },
};

function fixUpOrder(order)
{
  for (const subOrder of order.sub_orders) subOrder.key_json = stringify(subOrder.key);
  for (const fill of order.fills) fill.sub_order_key_json = stringify(fill.sub_order_key);
}

export default function useWs()
{
  useEffect(() =>
  {
    const ws = new ReconnectingWebSocket(async () =>
    {
      useGui.set(s => { s.wsStatus = {color: 'yellow', message: 'getting authentication'}; });
      try
      {
        const token = await fetch('/api/ws-login').then(resToJson);
        loginSocket = socket => socket.send(stringify({message: 'login', data: token}));
        useGui.set(s => { s.wsStatus = {color: 'yellow', message: 'connecting'}; });
      }
      catch (e)
      {
        useGui.set(s => { s.wsStatus = {color: 'red', message: 'login failed'}; });
        e && toast.error(<><div>{e.statusCode}: {e.error}</div><div>{e.message}</div></>);
      }

      return `wss://${window.location.host}/ws`;
    }, [], {
      connectionTimeout: 10000,
    });
    Object.entries(wsEvents).forEach(([type, listener]) => ws.addEventListener(type, data =>
    {
      try
      {
        listener.call(ws, data);
      }
      catch (e)
      {
        console.log('error handling message', type, e);
      }
    }));

    const calls = {};
    function returnCall(call, err, res)
    {
      const [resolve, reject, timeout] = calls[call];
      if (!call) return;
      delete calls[call];
      clearTimeout(timeout);
      if (err) return reject(err);
      resolve(res);
    }

    messageHandlers.rpcReturn = data => returnCall(data.call, data.err, data.res);

    let callId = 0;
    const callRpc = (fnName, data) => new Promise((resolve, reject) =>
    {
      const call = ++callId;
      calls[call] = [resolve, reject, setTimeout(() => returnCall(call, {details: `RPC ${fnName} timed out`}), 10 * 1000)];
      if (ws.readyState === ReconnectingWebSocket.OPEN)
      {
        ws.send(stringify({message: 'rpc', data: {fnName, call, data: {idempotency_key: uuid(), ...data}}}));
      }
    });

    const rpc = fnName => ({[fnName]: data => callRpc(fnName, data)});
    const message = message => ({[message]: data => ws.send(stringify({message, data}))});

    const setWs = useGui.getState().ws[1];
    setWs({
      ...rpc('traderAcknowledgeOrder'), // orderRequest
      ...rpc('flagHumanInteractionRequired'), // orderRequest
      ...rpc('unflagHumanInteractionRequired'), // orderRequest
      ...rpc('systemAcknowledgeSubOrder'), // subOrderRequest
      ...rpc('workSubOrder'), // subOrderRequest
      ...rpc('rejectSubOrder'), // rejectSubOrderRequest
      ...rpc('finishSubOrder'), // subOrderRequest
      ...rpc('updateOrderMetadata'), // orderRequest
      ...rpc('fillSubOrder'), // fillSubOrderRequest
      ...rpc('updateFill'), // updateFillRequest
      ...rpc('cancelFill'), // cancelFillRequest
      ...message('resetTally'),
    });

    const pingInterval = setInterval(() =>
    {
      clearTimeout(pingTimeout);
      if (ws.readyState !== ReconnectingWebSocket.OPEN) return;

      ws.send('');
      pingTimeout = setTimeout(() =>
      {
        useGui.getState().toastPingTimeout[0] && toast.error('Ping timed out, reconnecting...');
        console.log('ping timed out, reconnecting...');
        ws.reconnect();
      }, 1000);
    }, 10 * 1000);

    return () =>
    {
      const setWs = useGui.getState().ws[1];
      setWs(null);
      clearInterval(pingInterval);
      clearTimeout(pingTimeout);
      ws.close();
    }
  }, []);
}

const setAlertAudioElementNewOrder = el => alertAudioElementNewOrder = el;
const setAlertAudioElementPriceChanged = el => alertAudioElementPriceChanged = el;
const setAlertAudioElementOrderStopped = el => alertAudioElementOrderStopped = el;
export const useAudioAlertNewOrderRef = () => setAlertAudioElementNewOrder;
export const useAudioAlertPriceChangedRef = () => setAlertAudioElementPriceChanged;
export const useAudioAlertOrderStoppedRef = () => setAlertAudioElementOrderStopped;
let alertWindow = null;
const popupOptions = () => `popup,width=400,height=150,left=${window.screen.width / 2 - 200},top=${window.screen.height / 2 - 75}`;
export function showAlert()
{
  if (!alertWindow || alertWindow.closed)
  {
    alertWindow = window.open('/alert', 'alert', popupOptions());
    alertWindow && window.setTimeout(showAlert, 100);
  }
  else alertWindow.focus();
}

export const getAlertWindow = () => alertWindow;

let bettingVisible = false;
export function useBettingVisible()
{
  useEffect(() =>
  {
    bettingVisible = true;
    return () => bettingVisible = false;
  });
}
