export default function Badge({success, danger, warning, className, children})
{
  const color = danger
    ? 'bg-red-600'
    : success
      ? 'bg-green-700'
      : warning
        ? 'bg-yellow-500'
        : 'bg-current';
  return <span className={`${color} ${className} px-1.5 rounded-full flex`}><span className="text-white font-bold text-xs overflow-hidden overflow-ellipsis whitespace-nowrap">{children}</span></span>;
}
