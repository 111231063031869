import { DateTime } from "luxon";

window.DateTime = DateTime;

export function ts(ts)
{
  return DateTime.fromMillis(parseInt(ts)).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
}

export const toIndianRunsPrice = (isUnder, price) => isUnder ? (1 - (1 / (1 - price))) * 100 - 100 : price * 100 - 100;
export const toIndianOddsPrice = price => (price - 1) * 100;
export const toIndianRunsSize = (isUnder, price, size) => isUnder ? (price - 1) * size : size;

const trimFloatRegex = /(\.0)?0*$/;
export function toFixed(flt, digits, trim)
{
  const str = parseFloat(flt).toFixed(digits);
  return trim ? str.replace(trimFloatRegex, '$1') : str;
}

export const sidesIndian = {Under: 'No', Over: 'Yes'};
export const currencyScale = 1;
