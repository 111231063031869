export default function Button({ primary, danger, warning, link, submit, className, sm, ...props })
{
  return <button
    type={submit ? 'submit' : 'button'}
    {...props}
    className={`btn ${primary
      ? 'btn-primary'
      : danger
        ? 'btn-danger'
        : warning
          ? 'btn-warning'
          : 'btn-secondary'}
      ${sm ? 'btn-sm' : ''}
      ${link ? 'btn-link' : ''}
      ${className}`} />;
}
