import { useMemo } from 'react';
import ReactModal from 'react-modal';

ReactModal.setAppElement('#root');

const className = {
  base: 'modal-content',
  afterOpen: '',
  beforeClose: '',
};
const overlayClassName = {
  base: 'modal-overlay',
  afterOpen: 'modal-open',
  beforeClose: 'modal-closing',
};

function Modal({children, className: extraClassName, onSubmit, onRequestClose, ...props})
{
  const theClassName = !extraClassName
    ? className
    : {
      base: `${className.base} ${extraClassName}`,
      afterOpen: '',
      beforeClose: '',
    };
  const contentElement = useMemo(() => onSubmit
    ? (props, children) => <form {...props} onSubmit={async e => { e.preventDefault(); if (await onSubmit()) onRequestClose(); }}>{children}</form>
    : undefined, [onSubmit, onRequestClose]);

  return <ReactModal
    {...props}
    onRequestClose={onRequestClose}
    className={theClassName}
    overlayClassName={overlayClassName}
    bodyOpenClassName="overflow-hidden"
    closeTimeoutMS={200}
    contentElement={contentElement}>
    {children}
  </ReactModal>;
}

export default Modal;
