import produce from "immer";
import { unstable_batchedUpdates } from "react-dom";
import create from "zustand";
import useGui from "./gui";
import {stringify} from "./json";

// eslint-disable-next-line
let setImmediate;
let setOutside;

const useData = create(function(rawSet)
{
  const set = fn => rawSet(produce(fn));
  setImmediate = set;
  setOutside = fn => unstable_batchedUpdates(() => set(fn));

  return {
    users: getTableStore('users', () => fetch('/api/admin/users'), getJsonPoster('/api/admin/users'), getDeleter('/api/admin/users')),
    archive: getTableStore('archive', () => fetch(`/api/archive?${new URLSearchParams(useGui.getState().archiveSearchParams[0])}`), undefined, getDeleter('/api/admin/archive')),
    orders: null,
    betLog: [],
  };
});

export async function resToJson(res)
{
  if (!res.ok)
  {
    if (res.status === 401)
    {
      unstable_batchedUpdates(useGui.getState().authToken[1]);
      // don't set the error when we just need to log in
      // eslint-disable-next-line
      throw null;
    }

    throw await res.json();
  }

  return res.json();
}

function getTableStore(key, fetchFn, saveFn, deleteFn)
{
  return {
    data: null,
    loading: false,
    fetch: getFetcher(key, fetchFn),
    save: saveFn,
    delete: deleteFn,
    add: getAdder(key),
    remove: getRemover(key),
    clear: getClearer(key),
  };
}

// eslint-disable-next-line
function getLookupStore(key, fetchFn)
{
  return {
    data: null,
    loading: false,
    fetch: getFetcher(key, fetchFn),
    clear: getClearer(key),
  };
}

// eslint-disable-next-line
function getApi(key, saveFn)
{
  return {
    save: saveFn,
    // add: getNestedAdder(key),
  };
}

function getFetcher(key, fetchFn)
{
  let isLoading = false;
  return function()
  {
    if (isLoading) return;

    isLoading = true;
    setOutside(s => { s[key].loading = true; s[key].error = false; });
    fetchFn.apply(null, arguments)
      .then(resToJson)
      .then(data => setOutside(s => { s[key].data = data; s[key].loading = isLoading = false; }))
      .catch(e => setOutside(s => { s[key].loading = isLoading = false; s[key].error = e; }));
  };
}

export function getJsonPoster(url)
{
  return data => fetch(url, {method: 'POST', body: stringify(data), headers: {'Content-Type': 'application/json'}})
    .then(resToJson);
}

function getDeleter(url)
{
  return obj => fetch(`${url}/${obj.id || obj}`, {method: 'DELETE'}).then(resToJson);
}

function getAdder(key)
{
  return function(obj)
  {
    setOutside(s =>
    {
      if (s[key].data)
      {
        const i = s[key].data.findIndex(o => o.id === obj.id);
        if (i >= 0) s[key].data[i] = obj;
        else s[key].data.push(obj);
      }
      else
      {
        s[key].data = [obj];
      }
    });
  }
}

function getRemover(key)
{
  return function(obj)
  {
    setOutside(s =>
    {
      if (!s[key].data) return;

      const id = obj.id || obj;
      const i = s[key].data.findIndex(o => o.id === id);
      if (i >= 0) s[key].data.splice(i, 1);
    });
  }
}

function getClearer(key)
{
  return function()
  {
    setOutside(s =>
    {
      if (!s[key].data) return;
      s[key].data = null;
    });
  }
}

// function getNestedAdder(key)
// {
//   return function(obj, ...parents)
//   {
//     setOutside(s =>
//     {
//       if (s[key].data)
//       {
//         let parent;
//         while ((parent = parents.shift()))
//         {
//           const i = s[key].data.findIndex(o => o.id === obj.id);
//           if (i >= 0) s[key].data[i] = obj;
//           else s[key].data.push(obj);
//         }
//       }
//     });
//   }
// }

useData.set = setOutside;
export default useData;

// export function useImage(id)
// {
//   const [image, setImage] = useState({loading: !!id});
//   useEffect(function()
//   {
//     if (id) fetch(`/api/image/${id}`).then(resToJson).then(setImage).catch(setImage);
//   }, [id]);

//   return image;
// };
