import { lazy, Suspense } from "react";
import ErrorBoundary from "../components/ErrorBoundary";
import Button from "../components/Button";

const pages = new Map();

function ErrorContent({resetErrorBoundary, error})
{
  return <>
    <h1 className="text-3xl">This page has crashed :(</h1>
    <h2 className="text-2xl">We're so sorry</h2>
    {error && process.env.NODE_ENV === 'development' && <>
      <p>Here's some more info, maybe it'll help?</p>
      <div className="ml-4">
        <p className="text-red-600 block"><b>{error.name}</b>: {error.message}</p>
        {error.stack && <samp className="text-red-800 text-xs block whitespace-pre-wrap">{error.stack}</samp>}
      </div>
    </>}
    {resetErrorBoundary && <Button onClick={resetErrorBoundary} primary className="mt-4">Reload page</Button>}
  </>;
}

export function Error(props)
{
  return <Suspense fallback=""><ErrorContent {...props} /></Suspense>
}

export default function LazySafePage({page, ...props})
{
  let Page = pages.get(page);
  if (!Page) pages.set(page, Page = lazy(() => import(`./${page}`)));

  return <ErrorBoundary key={page} fallback={Error}>
    <Suspense fallback="Loading...">
      <Page {...props} />
    </Suspense>
  </ErrorBoundary>
}
