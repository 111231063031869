import { useState } from "react";
import { usePopper } from "react-popper";

export default function DropDown({button, children, className = undefined, placement = 'bottom-start', strategy = undefined})
{
  const [reference, setReference] = useState(null);
  const [popper, setPopper] = useState(null);
  const {styles, attributes, forceUpdate} = usePopper(reference, popper, {
    // modifiers: [{name: 'offset', options: {offset: [0, 12]}}],
    placement,
    strategy,
  });
  return <div role="button" tabIndex="0" className={`group ${className}`}
    onClick={e => (e.target.classList.contains('dismiss-menu') || e.target.tagName === 'A') && document.activeElement && document.activeElement.blur()}
    onFocus={forceUpdate}
    >
    <span ref={setReference} className="inline-block">{button}</span>
    <div className="cursor-auto hidden group-focus-within:block" ref={setPopper} style={styles.popper} {...attributes.popper}>
      {children}
    </div>
  </div>;
}
