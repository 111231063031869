// eslint-disable-next-line
'use strict';

const fillToBetLog = (order, fill) => ({
  orderId: order.order_id,
  filled: fill.filled_time,
  fixtureId: order.fixture.additional_info.FixtureId,
  fixtureDisplayName: order.fixture.display_name,
  competitors: order.market.market_type.toLowerCase() === 'matchoddswithdraw' ? [...order.fixture.competitors, 'The Draw'] : order.fixture.competitors,
  marketDisplayName: order.market.display_name,
  fillId: fill.fill_id,
  side: fill.sub_order_key.values.Side,
  selection: 'FillSelection' in fill.agent_metadata ? fill.agent_metadata.FillSelection : fill.sub_order_key.values.Selection,
  price: fill.price,
  size: fill.size,
  userId: fill.agent_metadata && fill.agent_metadata.uid,
  userName: fill.agent_metadata && fill.agent_metadata.una,
  sport: order.fixture.sport,
});
const fillToBetArchive = (order, fill) => ({
  ...fillToBetLog(order, fill),
  marketType: order.market.market_type,
});

module.exports = {
  orderToBetLogs(order)
  {
    return order.fills
      .filter(fill => fill.fill_status === 'CLIENT_ACCEPTED')
      .map(fill => fillToBetLog(order, fill));
  },
  orderToBetArchives(order)
  {
    return order.fills
      .filter(fill => fill.fill_status === 'CLIENT_ACCEPTED')
      .map(fill => fillToBetArchive(order, fill));
  },
};
