import produce from "immer";
import create from "zustand";
import utf8 from "utf8";
import base64 from "base-64";
import { useCallback, useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { parse, stringify } from "./json";
import { getAlertWindow } from "./ws";

function getCookie(name)
{
  return document.cookie.match(`(?:^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || '';
}

function parseAuthToken(cookie)
{
  try
  {
    if (!cookie) return cookie;
    const token = parse(utf8.decode(base64.decode(cookie.split('.').pop())));
    token.per = Object.freeze(new Set(token.per));
    return Object.freeze(token);
  }
  catch
  {
    return null;
  }
}

let setOutside;
const useGui = create(function(rawSet)
{
  const set = fn => rawSet(produce(fn));
  setOutside = fn => unstable_batchedUpdates(() => set(fn));

  function setter(key, doSet)
  {
    doSet = doSet || set;
    return v => doSet(s => { s[key][0] = v; });
  }

  function localStorageSetter(key)
  {
    return v =>
    {
      set(s => { s[key][0] = v; });
      window.localStorage.setItem(key, stringify(v));
      window.opener && window.opener.updateLocalStorageField(key);
      const alertWindow = getAlertWindow();
      alertWindow && !alertWindow.closed && alertWindow.updateLocalStorageField(key);
    };
  }

  const fields = {
    authToken: [parseAuthToken(getCookie('login')), () => set(s => { s.authToken[0] = parseAuthToken(getCookie('login')); })],
    wsStatus: {color: 'yellow', message: 'connecting'},
  };

  const simpleFields = {
    globalMessage: null,
    sidebar: null,
    sidebarProps: null,
    ws: null,
    apiConnected: false,
  };

  const localStorageFields = {
    numDigits: 2,
    trimZeros: true,
    showPopup: true,
    playAudioNewOrder: true,
    playAudioPriceChanged: true,
    playAudioOrderStopped: true,
    toastPingTimeout: false,
    archiveSearchParams: {numDays: 15},
  };

  const migrateLocalStorage = [
    ['playAudio', 'playAudioNewOrder'],
  ];

  for (const [oldKey, newKey] of migrateLocalStorage)
  {
    const value = window.localStorage.getItem(oldKey);
    if (value !== null)
    {
      window.localStorage.setItem(newKey, value);
      window.localStorage.removeItem(oldKey);
    }
  }

  for (let key in simpleFields)
  {
    const field = simpleFields[key];
    const [defaultValue, doSet] = Array.isArray(field) ? field : [field];
    fields[key] = [defaultValue, setter(key, doSet)];
  }

  for (let key in localStorageFields)
  {
    const initial = localStorageFields[key];
    const stored = window.localStorage.getItem(key);
    const defaultValue = stored !== null ? parse(stored) : initial;
    fields[key] = [defaultValue, localStorageSetter(key)];
  }

  return fields;
});

export function useSidebar(component, sidebarProps)
{
  const setSidebar = useGui(s => s.sidebar[1]);
  const setSidebarProps = useGui(s => s.sidebarProps[1]);
  useEffect(function()
  {
    setSidebar(component);
    setSidebarProps(sidebarProps);
    return setSidebar;
  }, [component, sidebarProps, setSidebar, setSidebarProps]);
}

export const entryToOption = e => ({value: e[0], label: e[1].name});

export function useLocalStorage(key, initial = undefined)
{
  const stored = window.localStorage.getItem(key);
  const [value, doSet] = useState(stored !== null ? parse(stored) : initial);
  const set = useCallback(v => { doSet(v); window.localStorage.setItem(key, stringify(v)); }, [doSet, key]);
  return [value, set];
}

useGui.set = setOutside;
export default useGui;

window.updateLocalStorageField = function(field)
{
  setOutside(s => { s[field][0] = parse(window.localStorage.getItem(field)); });
}
