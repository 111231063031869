import { useState } from "react";
import {BrowserRouter as Router, NavLink} from "react-router-dom";
import {Route, Routes, Navigate, Outlet} from "react-router";
import { HelmetProvider } from "react-helmet-async";
import { ToastContainer } from 'react-toastify';
import Settings from "./icons/Settings";
import Security from "./icons/Security";
import Logout from "./icons/Logout";
import useGui from "./utils/gui";
import useWs, { useAudioAlertNewOrderRef, useAudioAlertOrderStoppedRef, useAudioAlertPriceChangedRef } from "./utils/ws";
import {stringify} from "./utils/json";
import Button from "./components/Button";
import Modal from "./components/Modal";
import LazySafePage from "./pages/LazySafePage";
import Betting from "./pages/Betting";
import Alert from "./pages/Alert";
import CacheBuster from 'react-cache-buster';

import { version } from '../package.json';
import Inventory from "./icons/Inventory";

export const sidebarSize = 'w-40 lg:w-60 xl:w-80';
export const sidebarOffset = 'left-40 lg:left-60 xl:left-80';

const wsStatusColors = {
  green: 'text-green-500',
  yellow: 'text-yellow-500',
  red: 'text-red-600',
};
function AppContent()
{
  const [authToken, updateAuthToken] = useGui(s => s.authToken);
  const Sidebar = useGui(s => s.sidebar[0]);
  const sidebarProps = useGui(s => s.sidebarProps[0]);
  const wsStatus = useGui(s => s.wsStatus);
  const [, setGlobalMessage] = useGui(s => s.globalMessage);
  const apiConnected = useGui(s => s.apiConnected);
  useWs();

  async function logout()
  {
    setGlobalMessage('logging out...');
    document.cookie = 'login=;path=/';
    try
    {
      const res = await fetch('/api/logout', {method: 'POST'});
      const data = await res.json();
      if (data.error || !res.ok) throw data;
    }
    catch
    {
    }
    finally
    {
      updateAuthToken();
      setGlobalMessage();
    }
  }

  return <>
    <div className={`${sidebarSize} transition-all flex-shrink-0 text-black`}>
      <div className={`${sidebarSize} transition-all fixed flex flex-col pt-3 h-screen`}>
        <div className="flex break-all px-4">
          {authToken.nam}
          <button type="button" className="hover:text-green-600 transition-colors ml-auto flex-shrink-0" onClick={logout}><Logout className="w-6 h-6 max-w-full" /></button>
        </div>
        <div className="flex px-4 my-2 gap-2">
          <div className="mr-auto text-xs">
            <div className={wsStatusColors[wsStatus.color]}>{wsStatus.message}</div>
            {wsStatus.message === 'connected' && !apiConnected && <div className="text-red-600">API not connected</div>}
          </div>
          <NavLink className="transition-colors hover:text-green-600" to="/archive"><Inventory className="w-6 h-6" /></NavLink>
          {authToken && authToken.per.has('admin') && <NavLink className="transition-colors hover:text-green-600" to="/admin"><Security className="w-6 h-6" /></NavLink>}
          <NavLink className="transition-colors hover:text-green-600" to="/settings"><Settings className="w-6 h-6" /></NavLink>
        </div>
        {Sidebar && <Sidebar {...sidebarProps} />}
      </div>
    </div>
    <div className="bg-white shadow-lg w-full p-4 pb-3 z-20">
      <Outlet />
    </div>
    <audio src="/ding.mp3" ref={useAudioAlertNewOrderRef()} preload="auto" />
    <audio src="/alert.mp3" ref={useAudioAlertPriceChangedRef()} preload="auto" />
    <audio src="/stopped.mp3" ref={useAudioAlertOrderStoppedRef()} preload="auto" />
  </>;
}

function LoginPrompt()
{
  const [loggingIn, setLoggingIn] = useState(false);
  const [error, setError] = useState();
  const [, updateAuthToken] = useGui(s => s.authToken);

  async function tryLogin(e)
  {
    e.preventDefault();

    const userName = e.target.bettingSystemUserName.value.trim();
    if (!userName) return e.target.bettingSystemUserName.focus();
    const password = e.target.bettingSystemPassword.value;
    if (!password) return e.target.bettingSystemPassword.focus();
    const stayLoggedIn = e.target.bettingSystemStayLoggedIn.checked;


    setLoggingIn(true);
    setError();
    try
    {
      const res = await fetch('/api/login', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: stringify({userName, password, stayLoggedIn}),
      });
      const data = await res.json();
      if ((data && data.error) || !res.ok) throw data;
      window.setTimeout(updateAuthToken, 1);
    }
    catch (e)
    {
      setError(e && e.error && e.message ? e : {message: 'Error communicating with server, please try again later.', e});
    }
    finally
    {
      setLoggingIn(false);
    }
  }

  return <div className="modal-open">
    <form className="modal-content max-w-xl top-1/3" onSubmit={tryLogin}>
      <div className="bg-shade-700 flex font-bold border-b border-gray-200 p-6 pt-2.5 pb-1.5">
        {process.env.REACT_APP_APP_NAME} • Login
      </div>
      <div className="p-6 flex flex-col gap-4">
        <div className="flex flex-col xs:grid grid-cols-2-auto xs:gap-2 font-bold">
          <label className="w-full" htmlFor="bettingSystemUserName">Username/Email</label>
          <input type="text" className="bg-shade-900 focus:outline-shade-900 mb-4 xs:m-0" name="bettingSystemUserName" id="bettingSystemUserName" autoFocus />
          <label className="w-full" htmlFor="bettingSystemPassword">Password</label>
          <input type="password" className="bg-shade-900 focus:outline-shade-900 mb-4 xs:m-0" name="bettingSystemPassword" id="bettingSystemPassword" />
          <div className="hidden xs:block" />
          <label className="mr-auto"><input type="checkbox" name="bettingSystemStayLoggedIn" /> Stay logged in</label>
        </div>
        {error && <div className="text-red-700 bg-red-100 border-red-700 border-opacity-10 border text-sm rounded p-2">
          <div className="font-bold">Login Error:</div>
          {error.message}
          {error.e && process.env.NODE_ENV === 'development' && <div className="text-yellow-800 text-opacity-70 text-xs">{error.e.message}</div>}
        </div>}
        <Button primary submit className="self-end" disabled={loggingIn}>Login</Button>
      </div>
    </form>
  </div>;
}

const isProduction = process.env.NODE_ENV === 'production';
export default function App()
{
  const authToken = useGui(s => s.authToken[0]);
  const [globalMessage] = useGui(s => s.globalMessage);

  return <CacheBuster currentVersion={version} isEnabled={isProduction}>
    <HelmetProvider>
      <Router>
      {authToken
        ? <>
            <Routes>
              <Route path="/" element={<AppContent />}>
                <Route index element={<Betting />} />
                <Route path="*" element={<Navigate to="/" replace />} />
                <Route path="settings/*" element={<LazySafePage page="Settings" />} />
                <Route path="archive/*" element={<LazySafePage page="Archive" />} />
                {authToken.per.has('admin') && <Route path="admin/*" element={<LazySafePage page="Admin" />} />}
              </Route>
              <Route path="/alert" element={<Alert />} />
            </Routes>
          <Modal isOpen={!!globalMessage} className="top-1/3 p-4 w-auto">{globalMessage}</Modal>
          <ToastContainer
            toastClassName={() => 'bg-white shadow-lg relative flex p-1 mb-2 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer'}
            bodyClassName="text-sm text-black"
            closeButton={false}
            limit={5}
            hideProgressBar={true}
            />
        </>
        : <Routes>
          <Route path="/hidden-login" element={<LoginPrompt />} />
          <Route />
        </Routes>}
        </Router>
      <span className="text-xs text-gray-400 font-normal fixed right-4 bottom-3 pointer-events-none z-50">{version}</span>
    </HelmetProvider>
  </CacheBuster>;
}
