import { useCallback, useMemo, useState } from "react";
import ArrowDropUp from "../icons/ArrowDropUp";
import ArrowDropDown from "../icons/ArrowDropDown";

export default function SortedTh({field, index, onSort, sortField, sortDirection, children})
{
  const isSorted = field === sortField;

  return <th className={`${isSorted && sortDirection !== 0 ? 'border-b-2 border-green-600' : ''} cursor-pointer whitespace-nowrap`} onClick={() => onSort(field, index)}>
    {children}
    <div className="inline-block align-middle -mt-1 text-shade-600">
      <ArrowDropUp className={`-mb-4 -mt-2 ${isSorted && sortDirection === 1 ? 'text-green-600' : 'text-gray-300'}`} />
      <ArrowDropDown className={`-mt-4 -mb-2 ${isSorted && sortDirection === -1 ? 'text-green-600' : 'text-gray-300'}`} />
    </div>
  </th>;
}

export function useTableSorter(data, comparers)
{
  const [sortField, setSortField] = useState();
  const [sortDirection, setSortDirection] = useState(0);
  const [sortIndex, setSortIndex] = useState();
  const baseComparers = comparers;
  comparers = useMemo(function()
  {
    const comparers = [{}, null, {}];
    for (const field in baseComparers)
    {
      const comparer = baseComparers[field];
      comparers[0][field] = (l, r) => comparer(r, l);
      comparers[2][field] = comparer;
    }
    return comparers;
  }, [baseComparers]);

  const sorted = useMemo(() => sortDirection === 0 ? data : data?.slice().sort(comparers[sortDirection + 1][sortField]), [sortDirection, sortField, data, comparers]);
  const onSort = useCallback(function(field, index)
  {
    if (field === sortField)
    {
      setSortDirection((sortDirection + 2) % 3 - 1);
    }
    else
    {
      setSortField(field);
      setSortIndex(index);
      setSortDirection(sortDirection || 1);
    }
  }, [sortField, sortDirection, setSortField, setSortDirection, setSortIndex]);

  return {sorted, onSort, sortField, sortIndex: sortDirection && sortIndex, sortDirection};
}

const localeCompareOptions = {sensitivity: 'base', numeric: true};
useTableSorter.compareString = function compareString(l, r)
{
  const rIsNull = r === null;
  if (l === null) return rIsNull ? 0 : -1;
  if (rIsNull) return 1;
  return l.localeCompare(r, undefined, localeCompareOptions);
};
useTableSorter.getMappedCompare = function getMappedCompare(map, compare)
{
  return function compareMapped(l, r)
  {
    return compare(l === null ? null : map(l), r === null ? null : map(r));
  }
};
